import get from 'lodash/get';
import {assertTpp, getTPPServices} from '../tppServices/tppDi';
import { syncTranslationElements } from '../tppServices/translations/intlManager';
import { getCurrentLocale } from './language';

export const getMetaTitle = (page, portal) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  return t(get(messages, `${page}.metaElements.title`), { portal });
};

export const getMetaDescription = (page, portal) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  return t(get(messages, `${page}.metaElements.description`), { portal });
};

export const getTranslation = (path, slots = {}) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  return t(get(messages, path), { ...slots });
};

// This function will throw if we don't have a lang loaded in the language service.
export const urlFromLanguage = (url, lang, tpp) => {
  if (!tpp) {
    const services = getTPPServices();
    tpp = services.tpp;
  }
  assertTpp(tpp);
  let config = tpp.configService().getConfig();
  if (lang in config.languages) {
    return tpp.translateUrlSync(lang, url);
  }
  return `${config.host}${url}`;
};

export const getCanonical = (pathName) => {
  pathName = `${pathName ? (pathName.endsWith('/') ? pathName : `${pathName}/`) : '/'}`;
  return urlFromLanguage(pathName, getCurrentLocale());
};


export const getBannerText = (category) => {
  const { formatMessage: t, messages } = syncTranslationElements();
  return category ? t(get(messages, `blog.categoryPageSEO.${category}.h1`))
    : t(get(messages, 'blog.homePageSEO.h1'));
};

export const getSeoDataItems = (seoContent, type) => {
  let seoData = [];
  if (seoContent && seoContent.length > 0 && type) {
    seoContent.forEach( seoItem => {
      const listItem = seoItem.categories.find(category => category.includes(type));
      if (listItem) {
        seoData.push({
          title: seoItem.title,
          html: seoItem.html,
          text: seoItem.text,
          id: listItem
        });
      }
    });
  }
  return seoData.sort((a,b) => {
    return a.id > b.id ? 1 : -1;
  });
};
