import {
  generateSearchPath as generateBoatsSearchPath, parseSearchBoatsAppParams
} from '../../utils/urlHelpers/boats';
import get from 'lodash/get';
import {
  generateSearchPath as generateEnginesSearchPath, parseSearchEngineAppParams,
} from '../../utils/urlHelpers/engines';
import {languages, pageTypes} from './languageConstants';
import {generateBrandedOemSearchPath, parseBrandedOemSearchAppParams} from '../../utils/urlHelpers/oem';
import {getSupportedLanguages, translateDealer} from '../../utils/language';
import getTld from 'tld.js';
import {getConfig} from '../../config/portal';
import {getBoatsConstants} from '../../constants/boats';
import {getTempIntlContainers, temporaryI18nContainer} from './intlManager';
import {dealWithLocalhost} from '../../utils/urlHelpers/location';
import {assertTpp} from '../tppDi';

const translateLocation = (pathname, lang, pageType, intl, config) => {
  let route = '/';
  const normalTranslate = (pageType, append = '') => {
    return intl.routes[pageType] + append;
  };

  const searchBoatsTranslate = (pathname, isBranded = false) => {
    let searchParams = parseSearchBoatsAppParams(
      decodeURIComponent(pathname),
      isBranded
    );
    const isBroker = get(config, 'supports.useBrokerUrlPrefix', false);
    if (isBroker && searchParams.owner) {
      searchParams.owner = translateDealer(
        searchParams.owner,
        lang,
        intl.intl.formatMessage,
        isBroker
      );
    }
    const res = generateBoatsSearchPath({}, searchParams, undefined, lang);
    return res;
  };

  const dealerGalleryTranslate = (pathname) => {
    const isBranded = true;
    let searchParams = parseSearchBoatsAppParams(
      decodeURIComponent(pathname),
      isBranded
    );
    searchParams.owner = translateDealer(
      searchParams.owner,
      lang,
      intl.intl.formatMessage
    );
    const res = generateBoatsSearchPath({}, searchParams, undefined, lang);
    return res;
  };

  const enginesDealerGalleryTranslate = (pathname) => {
    const isBranded = true;
    let searchParams = parseSearchEngineAppParams(
      decodeURIComponent(pathname),
      isBranded
    );
    searchParams.owner = translateDealer(
      searchParams.owner,
      lang,
      intl.intl.formatMessage
    );
    const res = generateEnginesSearchPath({}, searchParams, undefined, lang);
    return res;
  };

  const searchEnginesTranslate = (pathname, isBranded = false) => {
    let searchParams = parseSearchEngineAppParams(
      decodeURIComponent(pathname),
      isBranded
    );
    return generateEnginesSearchPath({}, searchParams, undefined, lang);
  };

  switch (pageType) {
    case 'HOMEPAGE':
      route = normalTranslate(pageTypes.HOMEPAGE);
      break;
    case 'BOAT_DETAILS':
      route = normalTranslate(
        pageTypes.BOAT_DETAILS,
        pathname.split('/').slice(-2)[0] + '/'
      );
      break;
    case 'ENGINE_DETAILS':
      route = normalTranslate(
        pageTypes.ENGINE_DETAILS,
        pathname.split('/').slice(-2)[0] + '/'
      );
      break;
    case 'DEALERSEARCH':
      route = normalTranslate(pageTypes.DEALERSEARCH);
      break;
    case 'CLASS':
      route = normalTranslate(
        pageTypes.TYPE_CLASS,
        pathname.split('/').slice(-2)[0] + '/'
      );
      break;
    case 'TYPE_CLASS':
      route = normalTranslate(pageTypes.TYPE_CLASS);
      break;
    case 'BOATS':
      route = decodeURIComponent(searchBoatsTranslate(pathname));
      break;
    case 'BRANDED_SEARCH':
      route = searchBoatsTranslate(pathname, true);
      break;
    case 'BRANDED_OEM_SEARCH': {
      const searchParams = parseBrandedOemSearchAppParams(
        decodeURIComponent(pathname)
      );
      return generateBrandedOemSearchPath({}, searchParams, undefined, lang);
    }
    case 'DEALER_GALLERY':
      route = dealerGalleryTranslate(pathname);
      break;
    case 'ENGINES':
      route = searchEnginesTranslate(pathname);
      break;
    case 'ENGINES_DEALER_GALLERY':
      route = enginesDealerGalleryTranslate(pathname);
      break;
    case 'ENGINES_BRANDED_SEARCH':
      route = searchEnginesTranslate(pathname, true);
      break;
    case 'HEALTHCHECK':
      route = normalTranslate(pageTypes.HEALTHCHECK);
      break;
    // Code used to translate Blog, rigth now Blog only supported on portal config lang
    case 'BLOG':
      route = normalTranslate(pageTypes.BLOG);
      // istanbul ignore next
      if (!route.endsWith('/')) {
        route += '/';
      }
      break;
  }

  return route;
};

const extractPathname = (url) => {
  if (url.match(/^(http|https):\/\//)) {
    const urlObject = new URL(url);
    return urlObject.pathname;
  }
  return url;
};

const checkBlogType = (type, supportsBlog, isForSEOHead) => {
  if (type === 'BLOG') {
    if (isForSEOHead) {
      return '';
    }
    if (!supportsBlog){
      return 'HOMEPAGE';
    }
  }
  return type;
};

export const getTheCurrentPageType = (originalUrl, langIntl, supportsBlogHome) => {
  originalUrl = extractPathname(originalUrl);
  const currentPageType = langIntl.boats.getPageTypeFromPath(originalUrl, langIntl.intl.formatMessage);
  return checkBlogType(currentPageType, supportsBlogHome) || getPageType(originalUrl, supportsBlogHome);
};

export const translateUrlByLangCode = (tpp) => async (newLangCode, originalUrl) => {
  assertTpp(tpp);
  const langService = tpp.languageService();
  const configService = tpp.configService();
  const config = configService.getConfig();
  await langService.loadTranslationMessages(newLangCode);
  return translateUrlSync(newLangCode, originalUrl, langService, config);
};

/**
 * This function can be called to translate an url as long as we ensure we have the required translations
 * @param tpp
 * @returns {function(*, *): string}
 */
export const translateUrlByLangCodeSync = (tpp) => (newLangCode, originalUrl) => {
  assertTpp(tpp);
  const langService = tpp.languageService();
  const configService = tpp.configService();
  const config = configService.getConfig();
  return translateUrlSync(newLangCode, originalUrl, langService, config);
};

/**
 * We can call this function as long as we ensure we have the required translations on
 * the language service.
 * @param newLangCode language to be translated to
 * @param originalUrl url to translate
 * @param langService the language service
 * @param config the portal config
 * @returns string
 */
export const translateUrlSync = (newLangCode, originalUrl, langService, config) => {
    const allTranslationMessages = langService.allServiceTranslations();
    const langHost = langService.getHost();
    const langIntl = langService.getI18n();
    const tempI18 = temporaryI18nContainer(newLangCode, config, allTranslationMessages, langHost);
    const supportsBlogHome = config.languages[newLangCode].supportsBlogHome;
    const pageType = getTheCurrentPageType(originalUrl, langIntl, supportsBlogHome);
    const host = langIntl.host;

    const translateArguments = {
      pathname: originalUrl,
      language: newLangCode,
      pageType,
      tempI18,
      host,
      config
    };
    const fullRoute = translateUrl(translateArguments);
    return fullRoute;
};

export const redirectToNewLanguage = (tpp) => async (newLangCode, newPathName) => {
  let newUrl = await tpp.translateUrl(newLangCode, newPathName);
  newUrl = dealWithLocalhost(newUrl);
  // istanbul ignore next
  if (typeof window !== 'undefined') {
    tpp.empty();
    window.location.assign(newUrl);
  }
  // istanbul ignore next
  return null;
};

const removeAndReplaceDomain = (urlWithoutTld, domain) => {
  let originalUrlPieces = urlWithoutTld.split('://');
  let [protocol, url] = originalUrlPieces;
  let urlPieces = url.split('.');
  let tld = urlPieces.pop().split('$').pop();
  urlPieces.push(`${domain}$${tld}`);
  let finalUrl = urlPieces.join('.');
  return `${protocol}://${finalUrl}`;
};

const removeAndReplaceSubdomain = (urlWithoutTld, subdomain) => {
  let originalUrlPieces = urlWithoutTld.split('://');
  let [protocol, url] = originalUrlPieces;
  let urlPieces = url.split('.');
  if (urlPieces.length === 2) {
    if (subdomain) {
      urlPieces[0] = subdomain;
    } else {
      urlPieces = [urlPieces[1]];
    }
  } else if (subdomain) {
    urlPieces[0] = `${subdomain}.${urlPieces[0]}`;
  }
  let finalUrl = urlPieces.join('.');
  return `${protocol}://${finalUrl}`;
};

const addPathPrefixToUrl = (url, prefix) => {
  return url + prefix;
};

const buildFullUrl = (
  host,
  pathname,
  tld,
  subdomain,
  subDomainSupport,
  domain,
  pathPrefix
) => {
  const currentTld = '.' + getTld(host);
  let url = host.replace(currentTld, '$TLD');
  if (domain) {
    url = removeAndReplaceDomain(url, domain);
  }
  if (subDomainSupport) {
    url = removeAndReplaceSubdomain(url, subdomain);
  }
  if (pathPrefix) {
    url = addPathPrefixToUrl(url, pathPrefix);
  }
  url = url.replace('$TLD', tld);
  const resultUrl = url + pathname;
  return resultUrl;
};

export const getPageType = (pathName, supportsBlog = false, isForSEOHead = false) => {
  let type = 'HOMEPAGE';
  const tmpContainers = getTempIntlContainers();
  Object.keys(tmpContainers).forEach((lang) => {
    const tempIntl = tmpContainers[lang];
    const urlPatterns = tempIntl?.boatsConstants?.URL_PATTERNS;
    const formatMessage = tempIntl?.intl?.formatMessage;
    const t = tempIntl?.boats?.getPageTypeFromPath(pathName, formatMessage, urlPatterns);
    if (t) {
      type = t;
    }
  });
  return checkBlogType(type, supportsBlog, isForSEOHead);
};

export const translateUrl = ({
  pathname,
  language,
  pageType,
  tempI18,
  host,
  config
}) => {
  if (!config)  {
    throw new Error('config is required to translate url.');
  }
  const subDomainSupport = !!config.supports?.subDomains;
  const langsConfigs = config.languages;
  const languageConfig = langsConfigs[language];
  const route = translateLocation(pathname, language, pageType, tempI18, config);
  const domain = languageConfig.domain;
  const pathPrefix = languageConfig.pathPrefix;
  let fullRoute = buildFullUrl(
    host,
    route,
    languageConfig.tld,
    get(languageConfig, 'subdomain', ''),
    subDomainSupport,
    domain,
    pathPrefix
  );
  if (!process.env.USE_HTTP) {
    fullRoute = fullRoute.replace('http://', 'https://');
  }

  return fullRoute;
};

export const removeEnvironmentSubdomain = (host) => {
  let newHost;
  newHost = host.replace('qa.', '');
  newHost = newHost.replace('prod.', '');
  return newHost;
};

const removeProtocol = (tmpHost) => {
  if (tmpHost.includes('//')) {
    tmpHost = tmpHost.split('//');
    tmpHost.shift();
    tmpHost = tmpHost.join('');
  }
  return tmpHost;
};

export const getSubdomain = (host) => {
  let tmpHost = '' + host;
  tmpHost = host.replace(`.${getTld(tmpHost)}`, '');
  tmpHost = removeProtocol(tmpHost);
  tmpHost = tmpHost.split('.');
  if (tmpHost.length === 1 || tmpHost[0] === 'www') {
    return '';
  }
  return tmpHost[0];
};

export const getDomain = (host) => {
  let tmpHost = '' + host;

  const portalTld = '.' + getTld(tmpHost);

  tmpHost = tmpHost.split(portalTld)[0];
  tmpHost = removeProtocol(tmpHost);

  // remove ports for "local" qa with hosted port
  if (tmpHost.includes(':')) {
    tmpHost = tmpHost.split(':');
    tmpHost.pop();
    tmpHost = tmpHost.join('');
  }

  tmpHost = tmpHost.split('.');
  return tmpHost[tmpHost.length - 1].split('/')[0];
};

export const getLanguageFromUrl = (pathname, host, config) => {
  let language;
  let pathLanguage = '/';
  // TODO: when (if) async, remove this call to getConfig
  // istanbul ignore next
  if (!config) {
    config = getConfig();
  }
  //const config = getConfig();
  const langConfigs = config.languages;
  const pathFirstParam = pathname.split('/')[1];
  const currentTld = getTld(host) !== '' ? '.' + getTld(host) : undefined;
  const currentSubdomain = getSubdomain(host);
  const currentDomain = getDomain(host);
  let langSet = false;

  for (const lang in langConfigs) {
    if (!langSet) {
      const supportedTld = langConfigs[lang].tld;
      const supportedSubdomain =
        langConfigs[lang].subdomain === 'www'
          ? ''
          : langConfigs[lang].subdomain;
      const supportedDomain = langConfigs[lang].domain;
      if (currentTld === supportedTld) {
        // domain and folder type
        if (langConfigs[lang].type === 'domainFolder') {
          if (currentDomain === supportedDomain) {
            const firstParamIsLanguage =
              Object.keys(languages).includes(pathFirstParam);
            const isRoot =
              langConfigs[lang].url === 'root' && !firstParamIsLanguage;

            if (isRoot) {
              language = lang;
              pathLanguage = '';
              langSet = true;
            } else if (lang === pathFirstParam) {
              language = lang;
              pathLanguage = lang;
              langSet = true;
            }
          }
          continue;
        }

        // domain type
        if (langConfigs[lang].type === 'domain') {
          if (currentDomain === supportedDomain) {
            language = lang;
            pathLanguage = '';
            langSet = true;
          }
          continue;
        }

        // folder type
        if (lang === pathFirstParam && langConfigs[lang].type === 'folder') {
          pathLanguage = lang;
          language = lang;
          langSet = true;
        }

        // tld type
        if (
          (!currentSubdomain ||
            getBoatsConstants().IGNORED_SUBDOMAINS.includes(
              currentSubdomain
            )) &&
          langConfigs[lang].type === 'tld' &&
          !supportedSubdomain &&
          (pathFirstParam === '' ||
            !getSupportedLanguages(config).includes(pathFirstParam))
        ) {
          language = lang;
          pathLanguage = '';
          langSet = true;
        }

        // subdomain type
        if (
          currentSubdomain === supportedSubdomain &&
          langConfigs[lang].type === 'subdomain'
        ) {
          language = lang;
          pathLanguage = '';
          langSet = true;
        }

        // tld type with subdomain configured.
        if (
          (pathFirstParam === '' ||
            !getSupportedLanguages(config).includes(pathFirstParam)) &&
          langConfigs[lang].type === 'tld' &&
          supportedSubdomain === currentSubdomain
        ) {
          language = lang;
          pathLanguage = '';
          langSet = true;
        }
      }
      // localhost support
      if (!currentTld) {
        if (lang === pathFirstParam) {
          pathLanguage = lang;
          language = lang;
          langSet = true;
        }
      }
    }
  }

  if (!langSet) {
    pathLanguage = '';
    language = config.language;
  }
  return {
    language,
    pathLanguage
  };
};
