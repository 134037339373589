import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import filter from 'lodash/filter';

import includes from 'lodash/includes';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isObject from 'lodash/isObject';

import has from 'lodash/has';
import isString from 'lodash/isString';
import set from 'lodash/set';
import without from 'lodash/without';

import {generatePath, matchPath} from 'react-router';
import getTld from 'tld.js';
import {normalizeString as normalizeMakeModel} from '@dmm/lib-common/lib/formatting';
import {getCurrentLocale, getOverridableBoats, getOverridableRoutes,} from '../language';
import {normalizeString, translateMake, unhyphenateUrlComponents} from '../commonHelper';
import {getCurrencyFormat, getCurrencySymbol, getLengthSymbol, getRadius} from '../uomHelper';
import {toggleSubFacet} from '../multiFacetHelper';
import {getBoatsConstants, getLocalizedCountryCodes} from '../../constants/boats/index';
import {getConfig} from '../../config/portal';
import {getDefaultI18Service, getFormatMessageFunction} from '../../tppServices/tppDi';
import {getDomain} from '../../tppServices/translations/helpers';
import {getBoatConstantsFromI18n, getMappedValueFromI18n} from '../../tppServices/translations/constants';

import {parseReplacers} from './boatsConstantsReplacers';
import {parseBoatsAppUrlParams} from './boatsConstantsParsers';
import {getCountryURLFromCode, initializeGlobalSubdivisionsURLs, sharedHyphenate, slugify, unslugify} from './shared';
import { getMessages } from '../../tppServices/translations/messages';

export const generateMakeAndModelPathParam = (makeModel) => {
  let makes = Object.keys(makeModel).length;
  if (makes === 1) {
    let models = 0;
    Object.values(makeModel).map((item) => {
      models += item.length;
    });
    if (models < 2) {
      let make = Object.keys(makeModel)[0];
      let makeMod = { 'make': make };
      if (models === 1) {
        makeMod.model = makeModel[make][0];
      }
      return makeMod;
    }
  }
  return null;
};

export const getDealerGalleryPattern = (cfg, bt, owner, dealerStr) => {
  const re = new RegExp(`${dealerStr}-(.*)`);
  const dealerPrefix = re.test(owner);
  // Using broker in URL with all style urls not to break YW
  const broker = get(cfg, 'supports.useBrokerUrlPrefix', false);
  const dealerInRoot = broker || !dealerPrefix;
  const searchPattern = dealerInRoot
    ? bt.GENERATE_BRANDED_SEARCH_URL_PATTERN
    : bt.GENERATE_DEALER_GALLERY_URL_PATTERN;
  return searchPattern;
};

export const generateSearchPath = (
  changes,
  urlProps,
  newSearch,
  overrideLocale,
  i18Service
) => {
  if (!i18Service) {
    i18Service = getDefaultI18Service();
  }
  // locale is overriden only in translating location
  // We are not doing that anymore.
  // TODO: review if that is true
  const config = getOverridableRoutes(overrideLocale, i18Service);
  const bt = getOverridableBoats(overrideLocale, i18Service);
  const t = getFormatMessageFunction(i18Service, overrideLocale);
  const messages = getMessages();
  const tr = (message, params) => t(message, params);
  let removeLastSlash = false;
  let initialParams = {
    ...urlProps,
    ...changes
  };

  let finalParams = {};

  if (initialParams.modal) {
    delete initialParams.modal;
  }

  if (initialParams.makeModel) {
    if (!isEmpty(initialParams.makeModel)) {
      let seperateMakeModel = generateMakeAndModelPathParam(
        initialParams.makeModel
      );
      if (seperateMakeModel) {
        finalParams.make = `${config.SEARCH_URL_ITEM_MAKE}-${normalizeMakeModel(
          seperateMakeModel.make,
          getBoatsConstants().ALLOWED_CHARACTERS_IN_URL
        )}`;
        if (seperateMakeModel.model) {
          if (initialParams.modelRange) {
            delete initialParams.modelRange;
          }
          finalParams.model = `${
            config.SEARCH_URL_ITEM_MODEL
          }-${normalizeMakeModel(
            seperateMakeModel.model,
            getBoatsConstants().ALLOWED_CHARACTERS_IN_URL
          )}`;
        }
      } else {
        const models = flatten(
          Object.keys(initialParams.makeModel).map((makeName) => {
            const models = Object.values(
              get(initialParams.makeModel, makeName, [])
            );
            if (models.length) {
              return models.map((modelName) => {
                return `${makeName}:${modelName}`;
              });
            }
            return makeName;
          })
        ).filter((makeModel) => !!makeModel);
        finalParams.makeModel = `${
          config.SEARCH_URL_ITEM_MAKEMODEL
        }-${models.join('+')}`;
      }
    } else {
      delete initialParams.modelRange;
    }
    delete initialParams.makeModel;
  }

  if (initialParams.sort) {
    const [sortBy, sortOrder] = initialParams.sort.split(':');
    const translatedSort = `${tr(messages.search[sortBy])}:${tr(
      messages.sortFacet[sortOrder]
    )}`;
    finalParams.sort = `${config.SEARCH_URL_ITEM_SORT}-${translatedSort}`;
    delete initialParams.sort;
  }

  if (initialParams.condition) {
    if (initialParams.condition !== 'any') {
      const translatedCondition = tr(
        messages.conditionFacet[initialParams.condition]
      );
      finalParams.condition = `${config.SEARCH_URL_ITEM_CONDITION}-${translatedCondition}`;
    }
    delete initialParams.condition;
  }

  if (initialParams.keyword) {
    if (
      initialParams.searchKeywordParamFormat &&
      initialParams.searchKeywordParamFormat === 'query-string'
    ) {
      removeLastSlash = true;
      finalParams.keyword = `?${config.SEARCH_URL_ITEM_KEYWORD}=${initialParams.keyword}`;
    } else {
      finalParams.keyword = `${config.SEARCH_URL_ITEM_KEYWORD}-${initialParams.keyword}`;
    }
    delete initialParams.searchKeywordParamFormat;
    delete initialParams.keyword;
  }

  if (!isEmpty(initialParams.year)) {
    let year = [initialParams.year.min, bt.DEFAULT_MAX_YEAR + 1];

    if (
      initialParams.year.max &&
      initialParams.year.max < bt.DEFAULT_MAX_YEAR + 1
    ) {
      year[1] = initialParams.year.max;
    }

    finalParams.year = config.SEARCH_URL_ITEM_YEAR + '-' + year.join(',');

    delete initialParams.year;
  }

  if (initialParams.fuelType) {
    if (initialParams.fuelType.length > 0) {
      finalParams.fuelType = `${
        config.SEARCH_URL_ITEM_FUEL
      }-${initialParams.fuelType
        .map((fuel) => {
          return tr(messages.fuelTypeFacet[fuel]);
        })
        .join('+')}`;
    }
    delete initialParams.fuelType;
  }

  if (initialParams.hullMaterial) {
    if (initialParams.hullMaterial.length > 0) {
      finalParams.hullMaterial = `${
        config.SEARCH_URL_ITEM_HULL
      }-${initialParams.hullMaterial
        .map((material) => {
          return tr(messages.hullMaterialFacet[material]);
        })
        .join('+')}`;
    }
    delete initialParams.hullMaterial;
  }

  if (!isEmpty(initialParams.length)) {
    if (
      initialParams.length.max &&
      initialParams.length.max !== bt.SLIDER_MAX_VALUE
    ) {
      finalParams.length =
        config.SEARCH_URL_ITEM_LENGTH +
        `-${initialParams.length.min || 0},${initialParams.length.max}`;
    } else {
      finalParams.length = `${config.SEARCH_URL_ITEM_LENGTH}-${
        initialParams.length.min || 0
      }`;
    }
    delete initialParams.length;
  }

  if (!isEmpty(initialParams.engineOneHours)) {
    if (
      initialParams.engineOneHours.max &&
      initialParams.engineOneHours.max !== bt.SLIDER_MAX_VALUE
    ) {
      finalParams.engineOneHours =
        config.SEARCH_URL_ITEM_ENGINE_HOURS +
        `-${initialParams.engineOneHours.min || 0},${initialParams.engineOneHours.max}`;
    } else {
      finalParams.engineOneHours = `${config.SEARCH_URL_ITEM_ENGINE_HOURS}-${
        initialParams.engineOneHours.min || 0
      }`;
    }
    delete initialParams.engineOneHours;
  }

  if (initialParams.engine) {
    if (Array.isArray(initialParams.engine) && initialParams.engine.length > 0) {
      finalParams.engine = `${
        config.SEARCH_URL_ITEM_ENGINE
      }-${initialParams.engine
        .map((type) => {
          return type;
        })
        .join('+')}`;
    }
    delete initialParams.engine;
  }

  if (initialParams.engineMake) {
    if (Array.isArray(initialParams.engineMake) && initialParams.engineMake.length > 0) {
      finalParams.engineMake = `${
        config.SEARCH_URL_ITEM_ENGINE_MAKE
      }-${initialParams.engineMake
        .map((type) => {
          return slugify(type, '-');
        })
        .join('+')}`;
    }
    delete initialParams.engineMake;
  }

  if (!isEmpty(initialParams.multiFacetedBoatTypeClass)) {
    let typeList = [];
    let classList = [];

    if (initialParams.multiFacetedBoatTypeClass.power) {
      typeList.push('power');
      let filtered = initialParams.multiFacetedBoatTypeClass.power.filter(
        (item) => item !== 'power-all'
      );
      if (filtered.length) {
        classList = [...classList, filtered];
      }
    }
    if (initialParams.multiFacetedBoatTypeClass.sail) {
      typeList.push('sail');
      let filtered = initialParams.multiFacetedBoatTypeClass.sail.filter(
        (item) => item !== 'sail-all'
      );
      if (filtered.length) {
        classList = [...classList, filtered];
      }
    }
    if (initialParams.multiFacetedBoatTypeClass.unpowered) {
      typeList.push('unpowered');
      let filtered = initialParams.multiFacetedBoatTypeClass.unpowered.filter(
        (item) => item !== 'unpowered-all'
      );
      if (filtered.length) {
        classList = [...classList, filtered];
      }
    }
    if (typeList.length) {
      finalParams.types = `${config.SEARCH_URL_ITEM_TYPES}-${typeList
        .map((searchType) => {
          return tr(messages.typesFacet[searchType]);
        })
        .join('+')}`;
    }
    if (classList.length) {
      const translatedClasses = flatten(classList)
        .map((searchClass) => {
          return messages.classFacet[searchClass]
            ? tr(messages.classFacet[searchClass])
            : searchClass;
        })
        .join('+');
      finalParams.classes = `${config.SEARCH_URL_ITEM_CLASS}-${translatedClasses}`;
    }
    delete initialParams.multiFacetedBoatTypeClass;
    delete initialParams.group;
  }

  if (!isEmpty(initialParams.price)) {
    if (
      initialParams.price.max &&
      initialParams.price.max !== bt.SLIDER_MAX_VALUE
    ) {
      finalParams.price = `${config.SEARCH_URL_ITEM_PRICE}-${
        initialParams.price.min || 0
      },${initialParams.price.max}`;
    } else {
      finalParams.price = `${config.SEARCH_URL_ITEM_PRICE}-${
        initialParams.price.min || 0
      }`;
    }
    delete initialParams.price;
  }

  if (!isEmpty(initialParams.currency)) {
    const currentCurrency = initialParams.currency;
    finalParams.currency = `currency-${currentCurrency.toLowerCase()}`;
    delete initialParams.currency;
  }

  if (!isEmpty(initialParams.region)) {
    if (initialParams.region !== 'all') {
      const translatedRegion =
        initialParams.country === 'US' &&
        messages.regionFacet[initialParams.region]
          ? tr(messages.regionFacet[initialParams.region])
          : initialParams.region.toLowerCase();
      finalParams.region = `${config.SEARCH_URL_ITEM_REGION}-${translatedRegion}`;
    }
    delete initialParams.region;
  }

  if (!isEmpty(initialParams.country)) {
    if (initialParams.country !== 'all') {
      finalParams.country = getCountryURLFromCode(
        initialParams.country.toLowerCase(),
        overrideLocale
      );
    }
    delete initialParams.country;
  }

  if (!isEmpty(initialParams.subdivision)) {
    if (initialParams.subdivision !== 'all') {
      const localizedCountryCodes = getLocalizedCountryCodes();
      const country = (
        urlProps && urlProps.country
          ? urlProps.country
          : changes.country
          ? changes.country
          : get(getConfig(), 'country')
      ).toLowerCase();
      const subdivision = initialParams.subdivision.toLowerCase();
      const subdivisionURLCode = getSubdivisionURLFromCode(
        country,
        subdivision,
        overrideLocale
      );
      const useLocationSpecificSubdivisions = get(
        getConfig(),
        'supports.useLocationSpecificSubdivisions',
        false
      );
      if (useLocationSpecificSubdivisions && country === 'us') {
        finalParams.subdivision = `${tr(
          messages.search.subdivisionUS
        )}-${subdivisionURLCode}`;
      } else if (useLocationSpecificSubdivisions && country === 'ca') {
        finalParams.subdivision = `${tr(
          messages.search.subdivisionCA
        )}-${subdivisionURLCode}`;
      } else if (
        !useLocationSpecificSubdivisions &&
        localizedCountryCodes.includes(country)
      ) {
        const msg = messages.search[`subdivision${country.toUpperCase()}`];
        const localizedSubdivision = tr(msg);
        finalParams.subdivision = `${localizedSubdivision}-${subdivisionURLCode}`;
      } else {
        finalParams.subdivision = `${config.SEARCH_URL_ITEM_SUBDIVISION}-${subdivisionURLCode}`;
      }
    }
    delete initialParams.subdivision;
  }

  if (initialParams.city) {
    if (initialParams.city.length > 0) {
      finalParams.city = `${
        config.SEARCH_URL_ITEM_CITY
      }-${initialParams.city.join('+')}`;
    }
    delete initialParams.city;
  }

  if (!isEmpty(initialParams.worldRegion)) {
    if (!finalParams.country) {
      const translatedWorldRegion = tr(
        messages.worldRegionFacet[initialParams.worldRegion]
      );
      finalParams.worldRegion = `${config.SEARCH_URL_ITEM_WORLD_REGION}-${translatedWorldRegion}`;
    }
    delete initialParams.worldRegion;
  }

  if (initialParams.numberOfEngines) {
    if (initialParams.numberOfEngines > 0) {
      finalParams.numberOfEngines = `${config.SEARCH_URL_ITEM_ENGINE_NUMBER}-${initialParams.numberOfEngines}`;
    }
    delete initialParams.numberOfEngines;
  }

  if (initialParams.page) {
    if (newSearch) {
      delete initialParams.page;
      delete initialParams.pageSize;
    } else {
      if (initialParams.page !== '1' && initialParams.page !== 1) {
        finalParams.page = `${config.SEARCH_URL_ITEM_PAGE}-${initialParams.page}`;
      }
      delete initialParams.page;
      delete initialParams.pageSize;
    }
  }

  if (initialParams.forSale) {
    if (initialParams.forSale !== 'all') {
      finalParams.by = `${config.SEARCH_URL_ITEM_BY}-${tr(
        messages.forSaleFacet[initialParams.forSale]
      )}`;
    }
    delete initialParams.forSale;
  }

  if (initialParams.owner) {
    // TODO Verify correct format
    finalParams.owner = initialParams.owner;
    delete initialParams.owner;
  }

  if (initialParams.rep) {
    // TODO Verify correct format
    finalParams.rep = initialParams.rep;
    delete initialParams.rep;
  }

  if (!isEmpty(initialParams.group)) {
    const group = initialParams.group.toLowerCase();
    const finalGroup = messages.groupRoutes[group]
      ? tr(messages.groupRoutes[group])
      : group;
    finalParams.group = `${config.SEARCH_URL_ITEM_CLASSGROUP}-${finalGroup}`;
    delete initialParams.group;
  }

  finalParams = updateLocationPathParams(finalParams);

  for (let key in initialParams) {
    if (
      initialParams[key] !== bt.DEFAULT_REFINE_SEARCH[key] &&
      initialParams[key] &&
      !(
        typeof bt.DEFAULT_REFINE_SEARCH[key] === 'object' &&
        isEmpty(bt.DEFAULT_REFINE_SEARCH[key])
      )
    ) {
      const translatedKey = has(messages.search, key)
        ? tr(messages.search[key])
        : key.charAt(0).toLowerCase() + key.slice(1);
      finalParams[key] = `${translatedKey}-${initialParams[key]}`;
    }
  }

  // NOTE: To generate the URL we need to encode the value for each search parameter, but to accept it we don't ¯\_(⊙︿⊙)_/¯
  // NOTE: Maybe someone doesn't understand the previous note, left it here for posterity ¯\_(⊙︿⊙)_/¯
  let searchPattern = bt.GENERATE_SEARCH_URL_PATTERN;
  if (finalParams.owner) {
    const dealerStr = tr(messages.dealerPrefix);
    searchPattern = getDealerGalleryPattern(
      getConfig(),
      bt,
      finalParams.owner,
      dealerStr
    );
  }
  if (removeLastSlash) {
    return generatePath(searchPattern, finalParams).slice(0, -1);
  }

  return generatePath(searchPattern, finalParams);
};

// we want to remove region from url when we have subdivision and we should keep it in state
export const updateLocationPathParams = (params) => {
  if (params.region && (params.subdivision || params.city)) {
    delete params.region;
  }
  return params;
};

export const parseSearchBoatsAppParams = (url, isBranded = false, config, i18nService) => {
    if (!url) {
      return {};
    }
    const searchPattern = getSearchUrlPattern(url, isBranded);
    const matched = matchPath(decodeURI(url), searchPattern);
    if (matched) {
      let {params} = matched;
      return getDefaultParams(params, config, i18nService);
    }
    return {};
  };

export const getDefaultParams = (params, config, i18nService) => {
    if (!config) {
    // TODO: remove this call when needed
    config = getConfig();
  }

  const boatConstants = getBoatConstantsFromI18n(i18nService);
  const t = getFormatMessageFunction(i18nService);
  const mappedValue = getMappedValueFromI18n(i18nService);
  const parsers = parseBoatsAppUrlParams(boatConstants, config, t, mappedValue, i18nService);
  const {
    parseByParams,
    parseCityParams,
    parseClassGroupParams,
    parseConditionParams,
    parseCountryParams,
    parseCurrencyParams,
    parseEngineMakeParams,
    parseEngineNumberParams,
    parseEngineOneHoursParams,
    parseEngineTypeParams,
    parseFuelParams,
    parseHullParams,
    parseKeywordParams,
    parseLegacyMakeModelParams,
    parseLengthParams,
    parseMakeModelParams,
    parseModalParams,
    parseModelRangeParams,
    parseOwnerParams,
    parsePageParams,
    parsePostalCodeParams,
    parsePriceParams,
    parseRadiusParams,
    parseRegionParams,
    parseRepParams,
    parseSortParams,
    parseSubdivisionParams,
    parseTypeAndClassParams,
    parseWorldRegionParams,
    parseYearParams
  } = parsers;
  let parsed = {
    ...parseSubdivisionParams(params.subdivision),
    ...parseRegionParams(params.region),
    ...parseWorldRegionParams(params.worldRegion),
    ...parseCountryParams(params.country),
    ...parseCityParams(params.city),
    ...parsePostalCodeParams(params.postalCode),
    ...parseRadiusParams(params.radius),
    ...parseClassGroupParams(params.group),
    ...parseLegacyMakeModelParams(params.make, params.model),
    ...parseMakeModelParams(params.makeModel),
    ...parseModelRangeParams(params.modelRange),
    ...parseLengthParams(params.length),
    ...parseEngineTypeParams(params.engine),
    ...parseEngineMakeParams(params.engineMake),
    ...parsePageParams(params.page),
    ...parseSortParams(params.sort),
    ...parseConditionParams(params.condition),
    ...parseYearParams(params.year),
    ...parseFuelParams(params.fuelType),
    ...parseTypeAndClassParams(params.types, params.classes),
    ...parsePriceParams(params.price),
    ...parseCurrencyParams(params.currency),
    ...parseModalParams(params.modal),
    ...parseByParams(params.by),
    ...parseHullParams(params.hullMaterial),
    ...parseOwnerParams(params.owner),
    ...parseKeywordParams(params.keyword),
    ...parseRepParams(params.rep),
    ...parseEngineNumberParams(params.numberOfEngines),
    ...parseEngineOneHoursParams(params.engineOneHours),
  };

  let defaults = {
    ...boatConstants.DEFAULT_REFINE_SEARCH
  };

  defaults = Object.assign(defaults, parsed);
  return defaults;
};

export const getActiveParams = (params) => {
  const defaults = getDefaultParams({});
  const active = Object.entries(params).filter(([key, value]) => {
    return defaults[key] !== value;
  });

  const allActive = active.reduce(
    (object, [key, value]) => ({ ...object, [key]: value }),
    {}
  );

  return allActive;
};

export const getNotEmptyActiveParams = (params) => {
  const activeParams = getActiveParams(params);

  const filteredParams = {};
  for (const key in activeParams) {
    if (Array.isArray(activeParams[key]) && !activeParams[key].length ) {
      continue;
    }

    if (typeof activeParams[key] === 'object' && !Object.keys(activeParams[key]).length ) {
      continue;
    }

    if (!activeParams[key]) {
      continue;
    }

    filteredParams[key] = activeParams[key];
  }

  return filteredParams;
};

/**
 * @param selectedMakeSlug {string} - Selected make slug
 * @param selectedMakeModels {{[string]: [string]}} - Selected makes and models map
 * @param makeModel {[{value: string, count: string, model: [{value: string, count: string}]}]} - Available makes and model `value` is the make's formatted name
 * @returns {null|string}
 */
export const getFormattedMakeForFacet = (
  selectedMakeSlug,
  selectedMakeModels = {},
  makeModel
) => {
  if (!makeModel) {
    return null;
  }
  const selectedMake = makeModel.find(({ value: makeFormattedName }) => {
    const slugMake = normalizeMakeModel(
      hyphenateUrlComponents(makeFormattedName)
    );
    return has(selectedMakeModels, slugMake) && slugMake === selectedMakeSlug;
  });
  return get(selectedMake, 'value');
};

/**
 * @param facetKey {"city"}
 * @param selectedValues {[string]} - Facet value slugs
 * @param noFacet {{[string]: ( [string] | object | string )}} - Map of active facets without current facet
 * @param facetMeta {{ formattedKey: string }} - Translated facet name
 * @param defaultParams {object} - Map of facets with default values
 * @returns {array}
 */
const formatCity = (
  facetKey,
  selectedValues,
  noFacet,
  facetMeta,
  defaultParams
) => {
  return selectedValues.map((facetValue) => {
    let newValueSlug = without(selectedValues, facetValue);
    const formattedValue = unhyphenateUrlComponents(facetValue);

    const indexKey = `${facetKey}.${facetValue}`;
    const href = generateSearchPath(
      { ...noFacet, [facetKey]: newValueSlug },
      defaultParams,
      true
    );
    return {
      ...facetMeta,
      href,
      key: facetKey,
      indexKey,
      value: formattedValue,
      newValue: newValueSlug
    };
  });
};

/**
 * @param facetKey {"fuelType" | "hullMaterial"}
 * @param currentValue {string} - Current facet
 * @param selectedValues {[string]} - Facet value slugs
 * @param noFacet {{[string]: ( [string] | object | string )}} - Map of active facets without current facet
 * @param facetMeta {{ formattedKey: string }} - Translated facet name
 * @param defaultParams {object} - Map of facets with default values
 * @returns {array}
 */
const formatFuelHull = (
  facetKey,
  currentValue,
  selectedValues,
  noFacet,
  facetMeta,
  defaultParams,
  i18Service = getDefaultI18Service()
) => {
  const t = getFormatMessageFunction(i18Service);
  const messages = getMessages();
  const formattedValue = t(messages[`${facetKey}FacetText`][currentValue]);
  const indexKey = `${facetKey}.${currentValue}`;
  const href = generateSearchPath(
    { ...noFacet, [facetKey]: selectedValues },
    defaultParams,
    true
  );
  return {
    ...facetMeta,
    href: href,
    key: facetKey,
    indexKey,
    value: formattedValue,
    newValue: selectedValues
  };
};

/**
 * @param facetKey { "makeModel" | "multiFacetedBoatTypeClass" }
 * @param selectedValues {{[string]: [string]}} - Facet values map. Type => [Class] or Make => [Model]
 * @param noFacet {{[string]: ( [string] | object | string )}} - Map of active facets without current facet
 * @param facetMeta {{ formattedKey: string }} - Translated facet name
 * @param defaultParams {object} - Map of facets with default values
 * @param facetValue {string} - Selected Make or Type slug
 * @param subFacetValue {string} - Selected Model or Class slug
 * @param formattedMake {string} - Formatted Make name
 * @param makeModel {[{value: string, count: string, model: [{value: string, count: string}]}]} - Available makes and model `value` is the make's formatted name
 * @returns {object}
 */
// eslint-disable-next-line max-len
const formatModelClass = (
  facetKey,
  selectedValues,
  noFacet,
  facetMeta,
  defaultParams,
  facetValue,
  subFacetValue,
  formattedMake,
  makeModel,
  i18Service = getDefaultI18Service()
) => {
  const newValueSlug = {
    ...omit(selectedValues, facetValue),
    ...toggleSubFacet(facetValue, subFacetValue, selectedValues)
  };
  let formattedValue;
  let isFormatted;
  const t = getFormatMessageFunction(i18Service);
  const messages = getMessages();
  if (includes(getBoatConstantsFromI18n(i18Service).allTypes, subFacetValue)) {
    // Case multiFacetedBoatTypeClass: subfacet = all-*
    formattedValue = `${t(messages.allType, {
      boatType: t(messages[facetValue])
    })}`;
    isFormatted = true;
  } else if (facetKey === 'multiFacetedBoatTypeClass') {
    const formattedClass = has(messages.classFacetValues, subFacetValue)
      ? t(messages.classFacetValues[subFacetValue])
      : unslugify(subFacetValue);
    formattedValue = `${t(messages[facetValue])} - ${formattedClass}`;
    isFormatted = has(messages.classFacetValues, subFacetValue);
  } else {
    // Case makeModel
    const makeObj = makeModel.find(
      (e) => hyphenateUrlComponents(e.value) === facetValue
    ) || { value: unslugify(facetValue), model: [] };
    const modelObj = makeObj.model.find(
      (e) => hyphenateUrlComponents(e.value) === subFacetValue
    ) || { value: unslugify(subFacetValue) };
    formattedValue = `${formattedMake || makeObj.value} - ${modelObj.value}`;
    isFormatted = !!formattedMake;
  }

  const indexKey = `${facetKey}.${facetValue}.${subFacetValue}`;
  const href = generateSearchPath(
    { ...noFacet, [facetKey]: newValueSlug },
    defaultParams,
    true
  );

  return {
    ...facetMeta,
    href,
    key: facetKey,
    indexKey,
    value: formattedValue,
    newValue: newValueSlug,
    isFormatted
  };
};

/**
 * @param facetKey { "makeModel" }
 * @param selectedValues {{[string]: [string]}} - Facet values map. Make => [Model]
 * @param noFacet {{[string]: ( [string] | object | string )}} - Map of active facets without current facet
 * @param facetMeta {{ formattedKey: string }} - Translated facet name
 * @param defaultParams {object} - Map of facets with default values
 * @param facetValue {string} - Selected Make
 * @param formattedMake {string} - Formatted Make name
 * @param seoMakeInfo {[{make: string, seoMakeName: string}]} - Alternative make name suitable for SEO content
 * @returns {object}
 */
const formatMake = (
  facetKey,
  selectedValues,
  noFacet,
  facetMeta,
  defaultParams,
  facetValue,
  formattedMake,
  seoMakeInfo,
  i18Service = getDefaultI18Service()
) => {
  const newValueSlug = omit(selectedValues, facetValue);
  const t = getFormatMessageFunction(i18Service);
  const messages = getMessages();
  let formattedValue;
  if (!isEmpty(seoMakeInfo) && seoMakeInfo.make === facetValue) {
    formattedValue = `${t(messages.all)} ${seoMakeInfo.seoMakeName}`;
  } else {
    formattedValue = `${t(messages.all)} ${translateMake(
      formattedMake || unslugify(facetValue)
    )}`;
  }
  const isFormatted = !!formattedMake;
  const indexKey = `${facetKey}.${facetValue}`;
  const href = generateSearchPath(
    { ...noFacet, [facetKey]: newValueSlug },
    defaultParams,
    true
  );

  return {
    ...facetMeta,
    href,
    key: facetKey,
    indexKey,
    value: formattedValue,
    newValue: newValueSlug,
    isFormatted
  };
};

/**
 * @param facetKey {"subdivision"}
 * @param selectedValues {string} - The subdivision initials
 * @param noFacet {{[string]: ( [string] | object | string )}} - Map of active facets without current facet
 * @param facetMeta {{ formattedKey: string }} - Translated facet name
 * @param defaultParams {object} - Map of facets with default values
 * @returns {object}
 */
const formatSubdivision = (
  facetKey,
  selectedValues,
  noFacet,
  facetMeta,
  defaultParams,
  selectedCountry = get(getConfig(), 'country'),
  i18Service = getDefaultI18Service()
) => {
  const newValueSlug = defaultParams[facetKey];
  const t = getFormatMessageFunction(i18Service);
  const messages = getMessages();
  const href = generateSearchPath(
    { ...noFacet, [facetKey]: newValueSlug },
    defaultParams,
    true
  );
  let formattedValue;
  if (
    get(messages, `countrySubdivision.${selectedCountry}.${selectedValues}`)
  ) {
    formattedValue = t(
      get(messages, `countrySubdivision.${selectedCountry}.${selectedValues}`)
    );
  }
  const isFormatted = true;
  return {
    ...facetMeta,
    key: facetKey,
    href,
    value: formattedValue,
    newValue: newValueSlug,
    isFormatted
  };
};

/**
 * @param facetKey {"region"}
 * @param selectedValues {string} - The region selected
 * @param noFacet {{[string]: ( [string] | object | string )}} - Map of active facets without current facet
 * @param facetMeta {{ formattedKey: string }} - Translated facet name
 * @param defaultParams {object} - Map of facets with default values
 * @returns {object}
 */
const formatRegion = (
  facetKey,
  selectedValues,
  noFacet,
  facetMeta,
  defaultParams,
  i18Service = getDefaultI18Service()
) => {
  const newValueSlug = defaultParams[facetKey];
  const t = getFormatMessageFunction(i18Service);
  const messages = getMessages();
  const href = generateSearchPath(
    { ...noFacet, [facetKey]: newValueSlug },
    defaultParams,
    true
  );
  const formattedValue = t(messages.countryRegions[selectedValues]);
  const isFormatted = true;
  return {
    ...facetMeta,
    key: facetKey,
    href,
    value: formattedValue,
    newValue: newValueSlug,
    isFormatted
  };
};

/**
 * @param facetKey {"country"}
 * @param selectedValues {string} - The country selected
 * @param noFacet {{[string]: ( [string] | object | string )}} - Map of active facets without current facet
 * @param facetMeta {{ formattedKey: string }} - Translated facet name
 * @param defaultParams {object} - Map of facets with default values
 * @returns {object}
 */
const formatCountry = (
  facetKey,
  selectedValues,
  noFacet,
  facetMeta,
  defaultParams,
  i18Service = getDefaultI18Service()
) => {
  const newValueSlug = defaultParams[facetKey];
  const t = getFormatMessageFunction(i18Service);
  const messages = getMessages();
  const href = generateSearchPath(
    { ...noFacet, [facetKey]: newValueSlug },
    defaultParams,
    true
  );
  const formattedValue = t(messages.countries[selectedValues]);
  const isFormatted = true;
  return {
    ...facetMeta,
    key: facetKey,
    href,
    value: formattedValue,
    newValue: newValueSlug,
    isFormatted
  };
};

/**
 * @param facetKey {"numberOfEngines"}
 * @param selectedValues {string} - The number of engines selected
 * @param noFacet {{[string]: ( [string] | object | string )}} - Map of active facets without current facet
 * @param facetMeta {{ formattedKey: string }} - Translated facet name
 * @param defaultParams {object} - Map of facets with default values
 * @returns {object}
 */
const formatEnginesNumber = (
  facetKey,
  selectedValues,
  noFacet,
  facetMeta,
  defaultParams
) => {
  const formattedValue = parseInt(selectedValues) === getBoatsConstants().SRP_MAXIMUM_ENGINES_NUMBER_SEARCH ?
    `${selectedValues}+` : selectedValues;
  const isFormatted = true;
  const newValueSlug = defaultParams[facetKey];
  const href = generateSearchPath(
    { ...noFacet, [facetKey]: newValueSlug },
    defaultParams,
    true
  );

  return {
    ...facetMeta,
    key: facetKey,
    href,
    value: formattedValue,
    newValue: newValueSlug,
    isFormatted
  };
};

/**
 * @param facetKey {string} - condition, length, radius, year, postalCode, etc
 * @param selectedValues {string | { max: string, min: string }} - Facet value
 * @param noFacet {{[string]: ( [string] | object | string )}} - Map of active facets without current facet
 * @param facetMeta {{ prefix?: string, suffix?: string, format?: string, formattedKey: string }}
 * @param defaultParams {object} - Map of facets with default values
 * @returns {object}
 */
const formatDefaultFacets = (
  facetKey,
  selectedValues,
  noFacet,
  facetMeta,
  defaultParams,
  i18Service = getDefaultI18Service()
) => {
  const newValueSlug = defaultParams[facetKey];
  let isFormatted = false;
  const t = getFormatMessageFunction(i18Service);
  const messages = getMessages();
  let { prefix = '', suffix = '' } = facetMeta;
  if (isObject(selectedValues)) {
    // Case length, year or price
    if (selectedValues.min && !selectedValues.max) {
      suffix = '+ ' + suffix;
    } else if (!selectedValues.min && selectedValues.max) {
      prefix = `${t(messages.upTo)} ${prefix}`;
    } else if (!isEmpty(suffix)) {
      suffix = ' ' + suffix;
    }
  }
  let formattedValue =
    isString(selectedValues) && facetKey !== 'keyword'
      ? unslugify(selectedValues)
      : selectedValues;
  if (
    facetKey === 'condition' ||
    facetKey === 'forSale' ||
    facetKey === 'group'
  ) {
    formattedValue = t(messages[selectedValues]);
    isFormatted = true;
  }
  if (facetKey === 'radius' && has(messages, selectedValues)) {
    formattedValue = t(messages[selectedValues]);
    isFormatted = true;
  }
  if (facetKey === 'sort') {
    const [sortBy, sortOrder] = selectedValues.split(':');
    formattedValue = `${t(messages[sortBy])}:${t(
      messages.sortFacetValues[sortOrder]
    )}`;
    isFormatted = true;
  }
  if (facetKey === 'length') {
    isFormatted = true;
  }
  if (facetKey === 'keyword') {
    isFormatted = true;
  }

  if (facetKey === 'currency') {
    formattedValue = formattedValue && formattedValue.toUpperCase();
  }

  const href = generateSearchPath(
    { ...noFacet, [facetKey]: newValueSlug },
    defaultParams,
    true
  );
  // eslint-disable-next-line max-len
  return {
    ...facetMeta,
    href,
    key: facetKey,
    value: formattedValue,
    newValue: newValueSlug,
    prefix,
    suffix,
    isFormatted
  };
};

/**
 * @param active {{[string]: ([string] | object | string) }} - Map of active facets
 * @param makeModel {[{value: string, count: string}]} - Selected makes. and models `value` is the make's formatted name
 * @param seoMakeInfo {[{make: string, seoMakeName: string}]} - Alternative make name suitable for SEO content
 * @returns {array}
 */
export const getFormattedParamsArray = (
  active,
  makeModel = [],
  seoMakeInfo = {},
  customUom,
  i18Service = getDefaultI18Service()
) => {
  const currency = active.currency;
  const t = getFormatMessageFunction(i18Service);
  const messages = getMessages();
  const facetMetaMap = {
    radius: {
      suffix: getRadius(),
      format: 'number'
    },
    price: {
      [getCurrencyFormat() === 'postfix'
        ? 'suffix'
        : getCurrencyFormat(currency)]: getCurrencySymbol(currency),
      format: 'number'
    },
    length: {
      suffix: getLengthSymbol(customUom?.length),
      format: 'number'
    }
  };
  const defaultParams = getDefaultParams({});
  return flatten(
    Object.entries(active).map(([facetKey, selectedValues]) => {
      const facetMeta = {
        ...facetMetaMap[facetKey],
        formattedKey: t(messages[facetKey])
      };
      const noFacet = omit(active, facetKey);
      switch (facetKey) {
        case 'country':
          return formatCountry(
            facetKey,
            selectedValues,
            omit(noFacet, ['city', 'region', 'subdivision']),
            facetMeta,
            defaultParams
          );
        case 'city':
          return formatCity(
            facetKey,
            selectedValues,
            noFacet,
            facetMeta,
            defaultParams
          );
        case 'engine':
        case 'fuelType':
        case 'hullMaterial':
          return selectedValues.map((facetValue) => {
            const filteredFacets = filter(selectedValues, (val) => {
              return val !== facetValue;
            });
            return formatFuelHull(
              facetKey,
              facetValue,
              filteredFacets,
              noFacet,
              facetMeta,
              defaultParams
            );
          });
        case 'makeModel':
        case 'multiFacetedBoatTypeClass':
          // typeof selectedValues = { type: [class] } or { make: [model] }
          return flatten(
            Object.entries(selectedValues).map(([facetValue, subFacets]) => {
              // Case makeModel: facetValue is a Make
              const formattedMake = getFormattedMakeForFacet(
                facetValue,
                active.makeModel,
                makeModel
              );
              if (subFacets && subFacets.length) {
                return subFacets.map((subFacetValue) =>
                  formatModelClass(
                    facetKey,
                    selectedValues,
                    noFacet,
                    facetMeta,
                    defaultParams,
                    facetValue,
                    subFacetValue,
                    formattedMake,
                    makeModel
                  )
                );
              }
              // Case makeModel: There is always subfacets for type/class
              return formatMake(
                facetKey,
                selectedValues,
                noFacet,
                facetMeta,
                defaultParams,
                facetValue,
                formattedMake,
                seoMakeInfo
              );
            })
          );
        case 'subdivision':
          return formatSubdivision(
            facetKey,
            selectedValues,
            omit(noFacet, ['city']),
            facetMeta,
            defaultParams,
            get(active, 'country')
          );
        case 'region':
          // Removing the region should clear also the subdivision
          return formatRegion(
            facetKey,
            selectedValues,
            omit(noFacet, ['subdivision', 'city']),
            facetMeta,
            defaultParams
          );
        case 'numberOfEngines':
          return formatEnginesNumber(
            facetKey,
            selectedValues,
            noFacet,
            facetMeta,
            defaultParams
          );
        default:
          return formatDefaultFacets(
            facetKey,
            selectedValues,
            noFacet,
            facetMeta,
            defaultParams
          );
      }
    })
  );
};
/* eslint-enable max-len */

/**
 * @param params {{[string]: ([string] | object | string) }} - Map of facets with default values
 * @param getActive {boolean} - If true, get all active parameters before counting
 * @param excludePageParams {boolean} - If true, counts the number of active parameters excluding all page related ones
 * @returns {array}
 */
export const getActiveParamsLength = (
  params,
  getActive = true,
  excludePageParams = true
) => {
  const clonedParams = { ...params };
  if (excludePageParams) {
    delete clonedParams.page;
    delete clonedParams.pageSize;
  }

  if (
    clonedParams.multiFacetedBoatTypeClass &&
    isEmpty(clonedParams.multiFacetedBoatTypeClass)
  ) {
    delete clonedParams.multiFacetedBoatTypeClass;
  }

  const activeParams = getActive ? getActiveParams(clonedParams) : clonedParams;
  return Object.keys(activeParams).length;
};

export const getFormattedParams = (
  active,
  makeModel,
  seoMakeInfo,
  customUom
) => {
  if (getActiveParamsLength(active, false, true) === 1) {
    return getFormattedParamsArray(active, makeModel, seoMakeInfo, customUom);
  }
  return getFormattedParamsArray(active, makeModel, undefined, customUom);
};

export const getSearchUrlPattern = (url, isBranded, i18Service) => {
  const t = getFormatMessageFunction(i18Service);
  const messages = getMessages();
  const {
    BRANDED_SEARCH_URL_PATTERN,
    DEALER_GALLERY_URL_PATTERN,
    SEARCH_URL_PATTERN
  } = getBoatConstantsFromI18n(i18Service);
  if (!isBranded) {
    return SEARCH_URL_PATTERN;
  }
  const dealerStr = t(messages.dealerPrefix);
  const clearedPattern = DEALER_GALLERY_URL_PATTERN.replace(
    /:owner.+/,
    `${dealerStr}-`
  );
  const re = new RegExp(clearedPattern);
  return re.test(url) ? DEALER_GALLERY_URL_PATTERN : BRANDED_SEARCH_URL_PATTERN;
};

/**
 * We clear subdivision code from the subdivision for all the languages with a custom localized subdivision.
 * @param {string} subdivision
 * @param {Object} msgs messages passed to make tests easily
 * @param {function} formatMsg intl.formatMessage by default, or any formatter we want
 * @returns {string}
 */
export const swapLocalizedSubdivision = (subdivision, msgs, formatMsg, i18Service) => {
  const subdivisionConstants = getBoatConstantsFromI18n(i18Service);
  const countryCodes = ['US', 'CA', 'ES', 'IT'];
  countryCodes.forEach((code) => {
    const patternKey = `SEARCH_URL_SUBDIVISION_${code}_PATTERN`;
    const pattern = subdivisionConstants[patternKey];
    if (pattern.test(subdivision)) {
      const msg = msgs.search[`subdivision${code}`];
      subdivision = subdivision.replace(
        formatMsg(msg),
        formatMsg(msgs.search.subdivision)
      );
    }
  });
  return subdivision;
};

export const capitalizeFirstLetterOfEachWord = (str, splitter = ' ') =>
  String(str)
    .trim()
    .toLowerCase()
    .split(splitter)
    .map(word => word ? word[0].toUpperCase() + word.substring(1) : '')
    .join(splitter);

/**
 * This function has been moved to avoid circular dependencies.
 * We re-export to keep imports.
 * TODO: Fix imports and remove this re-export
 * @param string
 * @param removeSpecialCharacters
 * @returns {string|*}
 */
export const hyphenateUrlComponents = (
  string, removeSpecialCharacters = true
) => {
  return sharedHyphenate(string, removeSpecialCharacters);
};

export const singleHyphenateUrlComponents = (
  string,
  removeSpecialCharacters = true
) => {
  if (!isString(string)) {
    return '';
  }
  string = string.toLowerCase();
  // Remove accents and special characters
  if (removeSpecialCharacters) {
    string = normalizeString(string);
    const validChars = new RegExp(
      `[^${getBoatsConstants().ALLOWED_CHARACTERS_IN_URL}]+`,
      'g'
    );
    string = string.replace(validChars, ' ');
  }
  string = string.replace(/[ ]+/g, ' ');
  string = string.split('&').join('$');

  string = slugify(string, '-');
  string = string.replace(/-$/, '');
  string = string.replace(/^-/, '');
  return string;
};

export const reduceURLForRedirect = (props) => {
  let params = get(props, 'match.params', {});
  let lastParam;
  let modified = false;
  const boatsContstants = getBoatConstantsFromI18n();
  forEach(params, (value, key) => {
    if (!boatsContstants.INDEXABLE_PARAMS.includes(key)) {
      if (params[key]) {
        params[key] = undefined;
        modified = true;
      }
    }
  });
  let reducedUrl = generateSearchPath({}, getDefaultParams(params), true);
  if (!modified) {
    boatsContstants.INDEXABLE_PARAMS.forEach((matchParam) => {
      if (params[matchParam]) {
        lastParam = matchParam;
      }
    });
    delete params[lastParam];
    reducedUrl = generateSearchPath(
      {},
      getDefaultParams(params, lastParam),
      true
    );
  }

  return reducedUrl;
};

export const searchPathIsActive = (url) =>
  !!matchPath(decodeURI(url), getBoatConstantsFromI18n().SEARCH_URL_PATTERN);

export const searchBrandedPathIsActive = (url) =>
  !!matchPath(decodeURI(url), getBoatConstantsFromI18n().BRANDED_SEARCH_URL_PATTERN);

export const getSubdivisionURLFromCode = (
  countryCode,
  code,
  overrideLocale
) => {
  if (countryCode && code) {
    const locale = overrideLocale ? overrideLocale : getCurrentLocale();
    initializeGlobalSubdivisionsURLs(locale);
    const urls = global.url[locale].subdivisions.filter((subdivision) => {
      return subdivision.code === `${countryCode}-${code}`;
    });
    return urls.length > 0 ? `${urls[0].url}` : code;
  }
  return code;
};

export const getSearchPathUrl = (searchPath, host, { tld, type, domain }) => {
  let url = host;

  if (tld) {
    const currentTld = '.' + getTld(host);
    url = url.replace(currentTld, tld);
  }

  if (type === 'domain') {
    const prevDomain = getDomain(host);
    url = url.replace(prevDomain, domain);
  }

  return `${url}${searchPath}`;
};

export const redirectPartialMakeNameToFullMakeName = (
  props,
  fullMakeNameUrlParam,
  i18nService
) => {
  let params = get(props, 'match.params', {});
  const boatConstants = getBoatConstantsFromI18n(i18nService);
  forEach(params, (value, key) => {
    if (!boatConstants.INDEXABLE_PARAMS.includes(key)) {
      if (params[key]) {
        params[key] = undefined;
      }
    }
  });
  set(params, 'make', fullMakeNameUrlParam);
  return generateSearchPath({}, getDefaultParams(params), true);
};

export const isBDP = (url, language) => {
  const formatMessage = getFormatMessageFunction();
  const messages = getMessages();
  const boatPath = formatMessage(messages.detailsRoot, undefined, language);
  const boatPathRegex = new RegExp(`^(/[a-zA-Z]{2})?/${boatPath}/.*[0-9]+/$`);
  return boatPathRegex.test(url);
};

export const redirectBDPWrongUrls = (redirectPath, redirectCode, language) => {
  if (redirectPath.includes('/?')) {
    redirectPath = redirectPath.replace('/?', '-');
  }
  const redirectPathCount = redirectPath.split('/').length - 1;
  if (redirectPathCount > 3) {
    //URLs that include the language are /XX/ so we need to start counting from postion 4.
    const slashIndex = redirectPathCount === 5 ? 4 : 1;
    const redirectPathFirstPart = redirectPath.substring(
      0,
      redirectPath.indexOf('/', slashIndex)
    );
    if (redirectPathCount === 4 && redirectPathFirstPart.includes(language)) {
      return {
        redirectPath: redirectPath,
        redirectCode: redirectCode
      };
    }

    let redirectPathSubstring = redirectPath.substring(
      redirectPath.indexOf('/', slashIndex) + 1,
      redirectPath.lastIndexOf('/')
    );
    redirectPathSubstring = redirectPathSubstring.replace(/\//g, '-');
    return {
      redirectPath: `${redirectPathFirstPart}/${redirectPathSubstring}/`,
      redirectCode: 301
    };
  }
  return {
    redirectPath: redirectPath,
    redirectCode: redirectCode
  };
};

// TODO Move components to functional and call functions like this using useMemo
export const parseSearchParams = (url, isBranded = false, customUom, config, i18nService) => {
  if (!url) {
    return {};
  }
  // istanbul ignore next
  if (!config) {
    // TODO: remove this call when needed
    config = getConfig();
  }
  const searchPattern = getSearchUrlPattern(url, isBranded);
  const matched = matchPath(decodeURI(url), searchPattern);
  if (!matched) {
    return {};
  }
  let {params} = matched;
  const boatConstants = getBoatConstantsFromI18n(i18nService);
  const t = getFormatMessageFunction(i18nService);
  const mappedValue = getMappedValueFromI18n(i18nService);
  const parserReplacers = parseReplacers(boatConstants, config, t, mappedValue);
  const parsersBoats = parseBoatsAppUrlParams(boatConstants, config, t, mappedValue);
  const parseReplaceBoats = parserReplacers;
  const {
    parsePageParams, parseSubdivisionParams, parseTypeAndClassParamsToArray,
    parsePostalCodeParams, parseClassGroupParams, parseModelRangeParams
  } = parsersBoats;
  const {
    parseReplaceRegionParams, parseReplaceWorldRegionParams, parseReplaceCountryParams,
    parseReplaceCityParams, parseReplaceRadiusParams, parseReplaceLegacyMakeModelParams,
    parseReplaceMakeModelParams, parseReplaceLengthParams, parseReplaceEngineTypeParams,
    parseReplaceEngineMakeParams, parseReplaceSortParams,
    parseReplaceKeywordParams, parseReplaceConditionParams, parseReplaceYearParams,
    parseReplaceFuelParams, parseReplaceHullParams, parseReplacePriceParams,
    parseReplaceCurrencyParams, parseReplaceByParams, parseReplaceOwnerParams,
    parseReplaceRepParams, parseReplaceEngineNumberParams, parseReplaceEngineOneHoursParams
  } = parseReplaceBoats;
  const parsedParams = {
    ...parseSubdivisionParams(params.subdivision),
    ...parseReplaceRegionParams(params.region),
    ...parseReplaceWorldRegionParams(params.worldRegion),
    ...parseReplaceCountryParams(params.country),
    ...parseReplaceCityParams(params.city),
    ...parsePostalCodeParams(params.postalCode),
    ...parseReplaceRadiusParams(params.radius),
    ...parseReplaceLegacyMakeModelParams(params.make, params.model),
    ...parseReplaceMakeModelParams(params.makeModel),
    ...parseClassGroupParams(params.group),
    ...parseModelRangeParams(params.modelRange),
    ...parseReplaceLengthParams(params.length, customUom),
    ...parseReplaceEngineTypeParams(params.engine),
    ...parseReplaceEngineMakeParams(params.engineMake),
    ...parsePageParams(params.page),
    ...parseReplaceSortParams(params.sort, params.postalCode),
    ...parseReplaceKeywordParams(params.keyword),
    ...parseReplaceConditionParams(params.condition),
    ...parseReplaceYearParams(params.year),
    ...parseReplaceFuelParams(params.fuelType),
    ...parseReplaceHullParams(params.hullMaterial),
    ...parseTypeAndClassParamsToArray(params.types, params.classes),
    ...parseReplacePriceParams(params.price),
    ...parseReplaceCurrencyParams(params.currency),
    ...parseReplaceByParams(params.by),
    ...parseReplaceOwnerParams(params.owner),
    ...parseReplaceRepParams(params.rep),
    ...parseReplaceEngineNumberParams(params.numberOfEngines),
    ...parseReplaceEngineOneHoursParams(params.engineOneHours),
  };
  return parsedParams;
};
