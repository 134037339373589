import React, { useState, memo, useContext } from 'react';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DataLayer from '../../components/DataLayer';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AppBanner from '../../components/AppBanner';
import CookiesDisclaimer from '../../components/CookiesDisclaimer';
import './styles.css';
import SiteMeta from './SiteMeta';
import { PortalConfigContext } from '../../config/portal';
import OptionalScripts from './OptionalScripts';
import { isSafelyInitializedWithLayout } from '../../utils/commonHelper';

const Default = (props) => {
  const [state, setState] = useState({ appBannerHeight: 0 });

  const getAppBannerHeight = (appBannerHeight) => {
    setState({ appBannerHeight });
  };

  const context = useContext(PortalConfigContext);

  const detailClass = { 'with-sticky-contact': props.pageType === 'BoatDetails' || props.pageType === 'BoatDetailsOEM' || props.pageType === 'EngineDetails' };
  const homePageClass = { 'home-block': !!context?.pages?.home?.quickSearchBlock};
  const isAppBannerDisabled = !!context?.supports?.isAppBannerDisabled;
  const isSrp = props?.pageType === 'SearchResults';
  const isMobile = isSafelyInitializedWithLayout('mobile');
  const isLoading = props?.isWorking;

  return (
    <>
      <div className="page-container" id={ props.pageType } data-testid="layout-container">
        <DataLayer pageType={ props.pageType } brandID={ props.brandID } />
        <SiteMeta />
        { !isAppBannerDisabled && (
          <AppBanner
            cookies={ props.cookies }
            pageType={ props.pageType }
            getAppBannerHeight={ getAppBannerHeight }
          />
        ) }
        <Header
          appBannerHeight={ state.appBannerHeight }
          location={ props.location }
          pageType={ props.pageType }
          isWorking={ props.isWorking }
        />
        <div className={ classnames('content nav-slide', detailClass, homePageClass) } >
          { props.children }
          {(!isMobile || (isMobile && !isSrp) || (isMobile && isSrp && !isLoading)) && <Footer />}
          <CookiesDisclaimer cookies={ props.cookies } />
        </div>
      </div>
      <OptionalScripts page={ props.pageType } />
    </>
  );
};

Default.propTypes = {
  children: PropTypes.node,
  pageType: PropTypes.string,
  location: PropTypes.object,
  serverError: PropTypes.bool,
  cookies: PropTypes.instanceOf(Cookies),
  brandID: PropTypes.number,
  isWorking: PropTypes.bool
};

export default memo(Default);
